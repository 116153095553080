/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, FullmapCover, ContactForm, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"PEV Ústí nad Labem"}>
        <Column className="css-tl2z2q css-wwhiwb js-anim  --anim7 --anim-s4 --full --parallax pb--80 pt--80" style={{"marginBottom":0}} anim={"7"} name={"uvod"} animS={"4"} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37602/1b210b01cddf42cb9abfde10feba376f_s=3000x_.png);
    }
  
background-position: 50% 51%;
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"4"} animS={"3"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"var(--color-blend--95)"}} anim={null} name={"informace"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" anim={""} animS={"3"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-supplementary);\">Plyn</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">» Instalace</span><br>Rozvody plynu, plynové spotřebiče, spotřebiče<br><span style=\"font-weight: bold;\">»&nbsp;Revize</span><br>Revize plynovodů, spotřebičů<br><span style=\"font-weight: bold;\">»&nbsp;Opravy<br></span>Opravy kotlů, vafek a dalších spotřebičů<br><br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box" content={"Elektro<br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">» Instalace<br></span>Domovní instalace, průmyslové instalace<br><span style=\"font-weight: bold;\">» Revize</span><br>Revize rozvodů, spotřebičů, hromosvodů<br><span style=\"font-weight: bold;\">» Opravy<br></span>opravy rozvodů, rozvaděčů, spotřebičů<br><span style=\"font-weight: bold;\">» Data<br></span>instalace, konfigurace<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box" content={"Vytápění<br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">»&nbsp;Projektová dokumentace</span><br>Kompletní zpracování projektové dokumentace<br><span style=\"font-weight: bold;\">» Topenářské práce</span><br>Instalace a servis<br><span style=\"font-weight: bold;\">» Měření a regulace<br></span>kompletní realizace včetně správy&nbsp;<br><span style=\"font-weight: bold;\">» Kotelny<br></span>instalace a servis kotelen<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"7is923j0xo9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"O nás:"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Jsme firma sdružující odborníky zaměřené na provádění prací technických zařízeních budov. V oborech vytápění, plynoinstalace, vodoinstalace, elektroinstalace. Provedeme vás od návrhu řešení přes dodání materiálů, realizaci a následnou údržbu technických zařízení."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"o-mne"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--center" style={{"maxWidth":1200}} anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":"500"}}>
              
              <Title className="title-box fs--62" content={"Kontakt"}>
              </Title>

              <Text className="text-box fs--18 w--900 ls--002" style={{"maxWidth":500}} content={"Oficiální zastoupení společnosti&nbsp;&nbsp;Topimplynem s.r.o. pro Ústí nad Labem a okolí.<br>IČO&nbsp; :&nbsp;21012946<br>Tel. :&nbsp;\n+420 778470784<br>Email: ondrej.kucera@pevusti.cz<br>Adresa :&nbsp;Raisova 2, Střekov&nbsp;400 03, Ústí nad Labem<br>"}>
              </Text>

              <FullmapCover zoom={"12"} place={"Raisova 2 střekov"}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm name={"g9ufenczgxb"} action={"contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

              <Text className="text-box fs--8" content={"<span style=\"color: rgba(72, 81, 123, 0);\">Plynoservis Plynoservis Plynoservis Plynoservis Plynoservis Plynoservis Plynoservis\nInstalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Instalatér Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář Elektrikář pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost pohotovost kominík kominík kominík kominík kominík kominík kominík kominictví kominictví kominictví kominictví kominictví kominictví kominictvíZadejte text</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"wu7pt9uhwkb"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--center" content={"Naše práce"} url={"pevusti.cz/nase-prace"} href={"pevusti.cz/nase-prace"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}